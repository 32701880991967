
.docs__wrapper h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 30px;
}

.docs__wrapper td a:hover path {
    fill: var(--green);
}