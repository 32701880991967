textarea.request__message {
    width: 100%;
    height: 120px;
    background: var(--grey);
    border: none;
    outline-color: var(--green);
    resize: none;
    padding: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #575757;
    margin-bottom: 15px;
    margin-top: 15px;
}
textarea.request__message::placeholder {
    color: #575757;
    opacity: 0.5;
}

.buttonOk {
    padding: 5px 10px;
    background: var(--green);
    color: #fff;
    border: none;
    display: block;
    margin: 0 auto;
    transition: background .3s ease-out;
}

.buttonOk:hover {
    background: var(--green-hover);
}