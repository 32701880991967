.header {
    background: var(--dark-blue);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3003;
}
.header .navbar {
    padding: 15px 0;
}
.header .navbar .navbar__brand {
    display: block;
    width: 150px;
    height: 40px;
}
.header .navbar__wrapper svg path {
    transition: fill 0.3s ease-out;
}
.header .navbar__wrapper svg:hover path {
    fill: var(--gold);
    transition: fill 0.3s ease-out;
}

.header .navbar__wrapper > div + div {
    border-left: 1px solid #fff;
}
.header .navbar__wrapper .navbar__currency {
    list-style: none;
    padding-right: 20px;
    color: #fff;
    font-size: 14px;
    line-height: 18px;
}
.header .navbar__wrapper .navbar__currency .currency__desktop {
    white-space: nowrap;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header .navbar__wrapper .navbar__currency span {
    padding: 0 10px;
    position: relative;
}

.header .navbar__wrapper .navbar__currency span:first-child {
    padding-left: 0;
}

.header .navbar__wrapper .navbar__currency span:not(:last-child)::after {
    position: absolute;
    content: '/';
    right: -4px;
}

.header .navbar__info {
    padding: 0 15px;
}
.header .navbar__info > div {
    padding: 0 15px;
}
.header .navbar__account {
    padding: 0 30px;
}
.navbar__account_cab {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    line-height: 18px;
    margin-right: 30px;
    border-bottom: 1px solid #fff;
    transition: all 0.3s ease-out;
}
.navbar__account_cab:hover {
    border-bottom: 1px solid var(--green);
    color: var(--green);
}

.navbarAccountLogout {
    cursor: pointer;
}
.header .language__menu {
    padding-left: 18px;
}
.header .language__icon {
    margin-left: 5px;
}
.header .language__menu .languageMenuButton {
    font-size: 14px;
    line-height: 24px;
    transition: color 0.3s ease-out;
}
.header .language__menu .languageMenuButton path {
    transition: fill 0.3s ease-out;
}
.header .language__menu .languageMenuButton:hover {
    color: var(--green);
}
.header .language__menu .languageMenuButton:hover path {
    fill: var(--gold);
}
.header .notifications {
    min-width: 320px;
}
.header .notifications p {
    white-space: normal;
}

.closeNotifications {
    padding: 0 !important;
    cursor: pointer !important;
}



@media (max-width: 767px) {
    .navbarNav {
        overflow-y: scroll;
        max-height: 100vh;
    }
    .header .navbar__wrapper .navbar__currency .currency__desktop {
        white-space: normal;
    }
    .navbar__wrapper {
        margin-top: 20px;
        padding-bottom: 80px;
    }
    .header .navbar__wrapper .navbar__item {
        padding: 20px 0;
        border-bottom: 1px solid #fff;
        color: #fff;
        font-size: 14px;
        line-height: 18px;
    }
    .header .navbar__wrapper .navbar__item .navbar__account_cab {
        border-bottom: none;
    }
    .header .navbar__wrapper > div + div {
        border-left: none;
    }
    .navbar__wrapper .navbar__item a {
        color: #fff;
        font-size: 14px;
        line-height: 18px;
        transition: color 0.3s ease-out;
        margin-right: 5px;
    }
    .navbar__wrapper .navbar__item a:hover {
        color: var(--green);
    }
    .navbar__wrapper .navbar__item_list li {
        padding: 15px 0;
    }
    .navbar__wrapper .navbar__item_list li a {
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        display: flex;
        align-items: center;
        transition: color 0.3s ease-out;
        cursor: pointer;
    }
    .navbar__wrapper .navbar__item_list li a:hover {
        color: var(--green);
    }
    .navbar__wrapper .navbar__item_list li a:hover svg path {
        fill: var(--gold);
    }
    .navbar__wrapper .navbar__item_list li a svg {
        width: 20px;
        height: 20px;
        margin-right: 15px;
    }
    .navbar__wrapper .navbar__manager .navbar__manager_title {
        margin-bottom: 15px;
        display: block;
    }
    .navbar__wrapper .navbar__manager .photo {
        margin-right: 15px;
    }
    .navbar__wrapper .navbar__manager .photo img {
        border-radius: 50%;
        width: 60px;
        height: 60px;
    }
    .navbar__wrapper .navbar__manager .manager__name {
        margin-bottom: 10px;
    }
    .navbar__wrapper .navbar__manager .manager__links a {
        margin-right: 10px;
    }
}
@media (max-width: 767px) {
    .header .navbar {
        padding: 5px 0;
    }
    .header .navbar .navbar-brand {
        width: 90px;
    }
}
