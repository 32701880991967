.footer {
    padding-left: 80px;
    background-color: var(--dark-blue);
}
.footer__info {
    color: #fff;
    font-size: 14px;
    line-height: 100%;
    padding: 28px 0;
}
.confidentiality {
    color: #fff;
    text-decoration: underline;
    transition: color .3s ease-out;
    margin-left: 10px;
}
.confidentiality:hover {
     color: var(--green);
 }


@media (max-width: 767px) {
    .footer {
        padding-left: 0;
    }
}