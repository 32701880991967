.history__wrapper h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #171717;
    margin-bottom: 30px;
}

.history__wrapper .webs__filter {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--grey);
}

.history__wrapper .selected__options {
    color: #575757;
    margin-bottom: 15px;
}

.history__wrapper .selected__options p {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
}

.history__wrapper .selected__options .selected__block {
    margin-bottom: 5px;
}

.history__wrapper .selected__options .selected__block .selected__offer {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    font-weight: bold;
}

.history__wrapper .selected__options .selected__block .selected__option_item {
    padding: 6px 50px 6px 15px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
}

.history__wrapper .selected__options .selected__block .selected__option_item span {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.history__wrapper .buttons__block {
    margin-bottom: 20px;
}
