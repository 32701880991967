.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    padding-top: 70px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    background-color: #fff;
}
.sidebar .tooltip__menu::before,
.sidebar .tooltip__menu::after {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}
.sidebar .tooltip__menu::before {
    content: attr(data-title);
    width: max-content;
    background-color: rgba(0, 0, 0, 0.85);
    left: 115%;
    color: #fff;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
    font-size: 10px;
}
.sidebar .tooltip__menu::after {
    content: '';
    border-right: 7px solid rgba(0, 0, 0, 0.85);
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    left: 103%;
}
.sidebar .sidebar__menu_link:hover .tooltip__menu::before,
.sidebar .sidebar__menu_link:hover .tooltip__menu::after {
    visibility: visible;
    opacity: 1;
}
.sidebar .sidebar__menu {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.sidebar .sidebar__menu .sidebar__menu_links {
    flex: 1 0 auto;
}
.sidebar .sidebar__menu .hide__menu_icon {
    padding: 20px 0;
    cursor: pointer;

}

.sidebar .sidebar__menu .hide__menu_icon svg {
    transform: rotate(180deg);
    transition: transform 0.3s ease-out;
}

.sidebar .sidebar__menu .hide__menu_icon:hover {
    background: var(--green);
}

.sidebar .sidebar__menu .hide__menu_icon:hover svg path{
    fill: #fff
}

.sidebar .sidebar__menu .hide__menu_icon.toggled svg{
    transform: rotate(0);
}

.sidebar a {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    position: relative;
}
.sidebar a:hover svg path {
    fill: var(--green);
}
.sidebar__submenu {
    position: fixed;
    width: 240px;
    left: 60px;
    top: 0;
    padding-top: 70px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    transform: translateX(-310px);
    transition: transform, 0.3s ease-out;
}
.sidebar__submenu.toggled {
    transform: translateX(0px);
    visibility: visible;
    opacity: 1;
    transition: transform, 0.3s ease-out;
    z-index: 1052;
}
.sidebar__submenu .sidebar__submenu_wrap > a {
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-size: 14px;
    line-height: 14px;
    color: #171717;
    border-bottom: 2px solid var(--grey);
    position: relative;
}
.sidebar__submenu .sidebar__submenu_wrap > a:hover {
    color: #fff;
    background: var(--green);
    border: transparent;
    box-shadow: 0 0 3px rgb(0 0 0 / 25%);
}
.sidebar__submenu .sidebar__submenu_wrap > a:hover::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    right: -10px;
    top: 0;
    background: var(--green);
    box-shadow: 2px 0 3px rgb(0 0 0 / 25%);

}
.sidebar__submenu_wrap {
    flex: 1 0 auto;
}
.sidebar__submenu .sidebar__manager {
    padding: 30px;
    font-size: 14px;
    line-height: 14px;
    color: #171717;
    border-top: 2px solid var(--grey);
    border-bottom: 2px solid var(--grey);
}
.sidebar__submenu .sidebar__manager .sidebar__manager_title {
    margin-bottom: 15px;
    display: block;
}
.sidebar__submenu .sidebar__manager .photo {
    margin-right: 15px;
    min-width: 60px;
}
.sidebar__submenu .sidebar__manager .photo img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
}
.sidebar__submenu .sidebar__manager .manager__name {
    margin-bottom: 10px;
}
.sidebar__submenu .sidebar__manager .manager__links a {
    margin-right: 5px;
}
.sidebar__submenu .sidebar__manager .manager__links a svg path {
    transition: fill 0.3s ease-out;
}
.sidebar__submenu .sidebar__manager .manager__links a:hover svg path {
    fill: var(--gold);
}
.sidebar__submenu .sidebar__close_menu {
    font-size: 14px;
    line-height: 18px;
    padding: 26px 30px;
    cursor: pointer;
}

.sidebar__submenu .sidebar__close_menu:hover {
    background: var(--green);
    color: #fff;
}


@media (max-width: 991px) {
    .sidebar__menu_link {
        padding: 0 12px;
    }
    .sidebar__menu_link svg {
        width: 24px;
    }
    .sidebar__submenu {
        left: 54px;
    }
}