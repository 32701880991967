
.webs__wrapper h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #171717;
    margin-bottom: 30px;
}


.webs__wrapper .switch__btn {
    width: 50px;
    height: 20px;
    position: relative;
    background: var(--grey);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
    margin-right: 15px;
    cursor: pointer;
    transition: transform .3s ease-out;
}

.webs__wrapper .switch__btn::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: var(--dark-blue);
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    transition: transform .1s ease-out;
}

.webs__wrapper .switch__btn.toggled {
    background: var(--green);
}

.webs__wrapper .switch__btn.toggled::after {
    transform: translate(100%, -50%);
}

.webs__wrapper .switch__text {
    font-size: 14px;
    line-height: 14px;
    color: #575757;
}

.tooltipWebBlock {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
}
