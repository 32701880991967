@font-face {

    font-family: 'Geometria';

    src: url('./assets/fonts/Geometria-Regular.woff2') format('woff2');

    font-weight: normal;

    font-style: normal;

}

@font-face {

    font-family: 'Geometria';

    src: url('./assets/fonts/Geometria-Bold.woff2') format('woff2');

    font-weight: bold;

    font-style: normal;

}

.not-allowed {
    cursor: not-allowed !important;
}

.events-none {
    pointer-events: none !important;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}

.App > div {
    display: flex;
    flex-direction:  column;
    height: 100%;
}

body {
    font-family: Geometria, sans-serif;
    background: var(--grey);
}

html, body {
    height: 100%;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

ol, ul {
    margin-bottom: 0;
    list-style: none;
    padding: 0;
}

a {
    text-decoration: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.main {
    flex: 1 0 auto;
    padding: 130px 60px 60px 120px;
    transition: padding 0.3s ease-out;
}

.footer {
    flex: 0 0 auto;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--green);
}

::-webkit-scrollbar-track {
    background: #fff;
}

.dropdown-menu {
    min-width: fit-content;
    transform: translateX(-50%) !important;
    left: 50% !important;
    top: 46px !important;
}

.dropdown-menu a {
    transition: color 0.3s ease-out;
}

.dropdown-menu.show a:hover, .dropdown-menu.show a:focus {
    background-color: transparent;
    color: var(--green);
}

.dropdown-menu.show .primary-button:hover, .dropdown-menu.show .primary-button:focus {
    background-color: transparent;
    color: #fff;
}

.primary-button {
    background-color: var(--green) !important;
    width: fit-content;
    height: 40px;
    font-size: 14px;
    line-height: 100%;
    color: #fff;
    text-align: center;
    border-radius: 0;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-decoration: none;
    transition: background-color 0.3s ease-out;
    border: none;
    cursor: pointer;
}

.primary-button:hover {
    background-color: var(--green-hover) !important;
    color: #fff !important;
}

button.reset-button {
    font-size: 14px;
    line-height: 18px;
    color: #171717;
    text-decoration: underline;
    display: flex;
    align-items: center;
    transition: color 0.2s ease-out;
    background: none;
    border: none;
    outline: none;
}

button.reset-button:hover {
    color: var(--green);
}

img {
    max-width: 100%;
    max-height: 100%;
}

.btn.btn-primary, .btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
}

i.info-icon {
    display: inline-block;
    width: 15px;
    height: 15px;
    position: relative;
    background: url("./images/info.svg") no-repeat;
    cursor: pointer;
    top: 0.2rem;
    margin-left: 10px;
    z-index: 1;
}

.tooltip-inner {
    background-color: rgba(255, 255, 255, 0.9) !important;
    color: #575757;
    border: 1px solid var(--green);
    font-size: 10px;
    padding: 10px 5px;
    border-radius: 0;
}

.tooltip-arrow {
    display: none !important;
}

.dropdown-item {
    font-size: 14px;
    color: #fff;
}

.dropdown-item .notifications {
    padding: 10px 0;
    border-bottom: 1px solid #fff;
    cursor: pointer;
}

.nav-item .notifications {
    padding: 10px 0;
    cursor: pointer;
}

.dropdown-item .notifications.empty {
    cursor: default;
}

.dropdown-item .notificationsName,
.nav-item .notificationsName {
    margin-bottom: 20px;
    font-weight: 700;
}

.dropdown-item .notificationsDelete,
.nav-item .notificationsDelete {
    cursor: pointer;
    color: #F15B5B;
    font-weight: 700;
    border-bottom: 1px solid transparent;
}

.notifications-mob {
    padding: 20px 40px;
    display: none;
    transition: all .3s ease-in-out;
}

.notifications-mob.toggled {
    display: block;
    max-height: 300px;
    overflow: auto;
}

.notifications-mob .notifications {
    border-bottom: 1px solid #fff;
}

.dropdown-item .notificationsDelete:hover,
.nav-item .notificationsDelete:hover {
    border-bottom: 1px solid #F15B5B;
}

.dropdown-item.footer {
    padding: 20px;
}


.dropdown-item:hover, .dropdown-item:focus {
    background-color: var(--dark-blue);
    color: #fff;
}

.dropdown-menu {
    background-color: var(--dark-blue);
}

.dropdown-menu a, .dropdown-menu p, .dropdown-menu span {
    color: #fff;
}

#dropdownNotification {
    position: relative;
}

.supportReplies {
    position: absolute;
    top: -10px;
    right: -23px;
    padding: 0 5px;
    height: 20px;
    min-width: 20px;
    background: #f15b5b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

svg:hover ~ .supportReplies,
.nav-item a:hover .supportReplies {
    color: #fff;
    background: linear-gradient(
            270deg, #6BFF00 0%, #FFAA00 72.4%, #FF00C7 100%);
}

.current-lang {
    border-bottom: 1px solid #fff;
}

.lang-item {
    cursor: pointer;
}

.notification-word {
    position: relative;
}

.dropdown-item {
    max-height: 500px;
    overflow: auto;
}

.dropdown-item svg {
    margin-right: 5px;
    display: inline-block;
}

.dropdown-item:hover svg path {
    fill: var(--gold);
}

button.navbar-toggler {
    padding: 0;
}

button.navbar-toggler:focus {
    box-shadow: none;
}

.main__content {
    padding: 30px 60px 0;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.filters__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 50px;
    flex-wrap: wrap;
}

.table__wrapper {
    margin: 0 -60px;
    overflow: auto;
}

.table__wrapper .table {
    table-layout: fixed;
    min-width: 1000px;
    margin-bottom: 0;
}

.table__wrapper .table .table-header {
    background: var(--dark-blue);
    vertical-align: middle;
}

.table__wrapper .table .table-header th {
    color: #fff;
    font-weight: 400;
}

.table__wrapper .table .fcolumn, .table__wrapper .table .lcolumn {
    width: 60px;
}

.table__wrapper .table th {
    padding: 20px 8px;
    font-size: 14px;
    line-height: 14px;
    color: #575757;
}

.table__wrapper .table tbody tr:nth-child(even) {
    background: var(--grey);
}

.table__wrapper .table td {
    padding: 20px 8px;
    font-size: 14px;
    line-height: 14px;
    color: #575757;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table__wrapper .table tr {
    position: relative;
}


.table__wrapper .table td:hover {
    overflow: auto;
    text-overflow: initial;
}

.table__wrapper .table td.rejected {
    color: var(--rejected);
}

.table__wrapper .table td.approve {
    color: var(--green);
}

.table__wrapper .table td.pending {
    color: #f0b11f;
}

.select2.select2-container.select2-container--default {
    width: 200px !important;
    margin-right: 10px;
}

.select2-container--default .select2-selection--single {
    background: var(--grey) !important;
    height: 30px !important;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.select2-selection__rendered {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #575757 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
}

.select2-selection__arrow {
    background: url("./images/arrow-down.svg") center center no-repeat;
    background-size: 50% 50%;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: var(--green) !important;
}

.modal-body .select2.select2-container.select2-container--default {
    width: 100% !important;
}

.select2-container--open {
    z-index: 3004;
}

.select2-results__option--selectable {
    color: #575757;
}

.btn-close:focus {
    box-shadow: 0 0 0 0.25rem var(--green);
}

.select2-search__field {
    outline: none;
}

.select2-dropdown {
    border: none !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    background-color: var(--grey) !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: none !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.select2-container--default .select2-results__option--selected {
    background-color: var(--grey) !important;
}

.extra__filter--block > * {
    margin-right: 10px;
    margin-bottom: 10px;
    height: 35px;
    color: #575757;
    width: 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input {
    background: var(--grey);
    padding: 2px 8px;
    outline: none;
    box-shadow: 0 0 2px rgb(0 0 0 / 25%) !important;
    border: none;
}

select {
    user-select: none;
}

.filter__control {
    width: 200px;
}

.data__config .stat-select-wrap {
    width: 320px;
    min-height: 40px;
    padding-left: 50px !important;
    cursor: pointer;
    padding-right: 50px !important;
    background: var(--grey);
    border: none;
    font-size: 16px;
    text-align: center;
    outline-color: var(--green);
    position: relative;
    box-shadow: 0 0 2px rgba(0, 0, 0, .25);
    font-weight: bold;
}

.data__config .range__wrapper {
    position: relative;
}

.data__config .range__wrapper:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    background: url("./images/calendar.svg") no-repeat;
    width: 20px;
    height: 20px;
    pointer-events: none;
    z-index: 1;
}

.data__config .range__wrapper:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 2rem;
    height: 1rem;
    pointer-events: none;
    background: url("./images/arrow-down.svg") no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}


.daterangepicker {
    background: var(--grey);
    font-family: "Geometria Regular", sans-serif;
    box-shadow: 0 0 2px rgba(0, 0, 0, .25);
}

.daterangepicker .calendar-table {
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, .25);
}

.daterangepicker td.in-range, .daterangepicker td.active, .daterangepicker td.active:hover, .daterangepicker .ranges li.active {
    background-color: var(--green) !important;
    color: #fff;
}

.daterangepicker .applyBtn.btn-primary {
    background-color: var(--green);
    border-color: var(--green);
}

.daterangepicker .applyBtn.btn-primary:hover {
    background-color: var(--green-hover);
}

.modal {
    z-index: 3004;
}

.modal .modal-dialog {
    max-width: 420px;
    margin: 90px auto;
}

.modal .modal-dialog .modal-header {
    border: none;
}

.modal .modal-dialog .modal-body {
    padding: 8px 60px 30px;
    overflow: auto;
}

.modal .modal-dialog .modal-body h3 {
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid var(--grey);
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #171717;
}

.modal .modal-dialog .modal-body p {
    font-size: 14px;
    line-height: 18px;
    color: #575757;
    text-align: center;
    margin-bottom: 15px;
}

.modal .modal-dialog .modal-body .progress {
    height: 30px;
    background-color: var(--grey);
    border-radius: 0;
    position: relative;
}

.modal .modal-dialog .modal-body .progress .progress-bar {
    background-color: var(--green);
}

.modal .modal-dialog .modal-body .progress .progress-bar span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #575757;
    font-size: 14px;
    line-height: 18px;
}

.modal .modal-dialog .modal-body .primary-button {
    width: 100%;
}

.modal-backdrop {
    z-index: 3003;
}

.modal .modal-dialog.modal-90w .modal-body .primary-button {
    width: fit-content;
}

.modal .modal-dialog.modal-90w .modal-body .primary-button svg{
    margin-left: 10px;
}

.modal-90w {
    width: 90%;
    max-width: none!important;
}

.modal .pdl__logo {
    width: 150px;
    margin-left: 10px;
}

.modal .chart-main-container {
    margin: 20px 0;
    padding: 0 30px;
}

.modal .offers__logo--pdf {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal .offer__stats__wrap--pdf {
    padding: 20px 30px;
}

.modal .chart-offers-container {
    margin-bottom: 20px;
}

.modal .modal-dialog .modal-body .stats__summary-pdf {
    margin: 20px 0;
}

.modal .modal-dialog .modal-body .stats__summary-pdf p{
    font-size: 12px;
    line-height: 100%;
    text-align: left;
    padding: 0 40px;
}

.modal .modal-dialog .modal-body .stats__summary-pdf p:last-child{
    margin-bottom: 0;
}

.modal .offers__name--pdf {
    font-weight: bold;
}

.modal .pdf__title {
    margin-bottom: 10px;
}

.modal .pdf__date {
    margin-right: 20px;
    margin-bottom: 10px;
}

.modal .chart__offers--pdf {
    display: flex;
    flex-wrap: wrap;
    margin: -10px 10px;
}

.modal .pdf__wrapper {
    min-width: 1200px;
}

.data__config {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--grey);
}

.data__config .primary-button span {
    margin-left: 10px;
}

.primary-button.read-all {
    cursor: pointer;
}

.nav-link {
    color: #f0b11f;
}

.dropdown-toggle::after {
    content: none !important;
    width: 100px;
}

.progress__block {
    position: relative;
}

.progress__block span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #575757;
    font-size: 14px;
    line-height: 18px;
}

.pagination__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 15px 30px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.pagination__block ul {
    display: flex;
}

.pagination__block li.previous,
.pagination__block li.next {
    display: none;
}

.pagination__block ul li + li {
    margin-left: 10px;
}

.pagination__block ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 0 12px;
    background: var(--grey);
    color: var(--dark-blue);
}


.pagination__block ul li.selected a {
    background: var(--dark-blue);
    color: #fff;
    font-size: 14px;
}

.pagination__block ul li a:hover {
    background: var(--dark-blue);
    color: #fff !important;
}

.noData {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
}

.chart-main-container {
    height:50vh;
}

.chart-offers-container {
    height: 30vh;
}

.sidebar__menu a.active {
    background: var(--green);
    color: #fff;
    border: transparent;
    box-shadow: 0 0 3px rgb(0 0 0 / 25%);
}

.sidebar__menu a.active::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 10px;
    right: -10px;
    top: 0;
    background: var(--green);
    box-shadow: 2px 0 3px rgb(0 0 0 / 25%);
}

.sidebar__menu--svg.active svg path{
    fill: var(--green)
}

.sort__col {
    cursor: pointer;
    transition: color .3s ease;
}

.table__wrapper .table .table-header th.sort__col:hover {
    color: var(--green)
}

.table__wrapper .table td a.webId {
    cursor: pointer;
    color: #575757;
    transition: color .3s ease;
}

.table__wrapper .table td a.webId:hover {
    color: var(--green);
}

@media (max-width: 991px) {
    .main {
        padding: 100px 50px 30px 100px;
    }

    .table__wrapper {
        margin: 0 -40px;
        overflow-x: auto;
    }

    .table__wrapper .table {
        min-width: 900px;
    }

    .table__wrapper .fcolumn, .table__wrapper .lcolumn {
        width: 40px;
    }

    .main__content {
        padding: 30px 40px 0;
    }
}

@media (max-width: 767px) {
    .main {
        padding: 80px 0 30px;
    }

    .main__content {
        padding: 20px 20px 0;
    }

    .table__wrapper {
        margin: 0 -20px;
    }

    .select2.select2-container.select2-container--default {
        width: 100% !important;
    }

    .select2.select2-container.select2-container--default:not(:first-child) {
        margin-top: 10px;
    }

    .data__config .data__export {
        margin-bottom: 10px;
    }

    .data__config .data__export .primary-button {
        width: 100%;
    }

    .data__config .stat-select-wrap {
        height: 40px;
        width: 100%;
    }


    .modal .modal-dialog .modal-body {
        padding: 8px 12px 30px;
    }


    .pagination__block {
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .pagination__block > div:first-child {
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    .data__config .stat-select-wrap {
        padding-left: 30px!important;
        padding-right: 30px!important;
        font-size: 14px;
    }

    .data__config .range__wrapper:before {
        left: 0.5rem;
    }

    .data__config .range__wrapper:after {
        width: 1.5rem;
    }

    .modal .chart-main-container {
        padding: 0;
    }
    .modal .modal-dialog .modal-body .stats__summary-pdf p {
        padding: 0;
    }
    .modal .offer__stats__wrap--pdf {
        padding: 20px 0;
    }
    .filters__wrapper {
        gap: 30px;
    }
}

