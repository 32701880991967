.wrapper {
    background: #e9ecef;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.card {
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    width: 360px;
}

.cardBody {
    background-color: #fff;
    color: #666;
    padding: 20px;
}

.cardMsg {
    margin: 0;
    padding: 0 20px 20px;
    text-align: center;
}

form > div {
    margin-bottom: 15px;
}

form input {
    border-radius: 5px;
    padding: 5px 10px;
    color: #495057;
    border: 1px solid #ced4da;
    outline: none;
}

form input:focus {
    border: 1px solid var(--green)
}

form label {
    cursor: pointer;
    margin-left: 10px;
}

form button {
    color: #fff;
    background: var(--green);
    box-shadow: none;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background .3s ease-out;
}

form button:hover {
    background: var(--green-hover);
}