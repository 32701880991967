.filter__value-container {
    cursor: pointer;
    font-size: 14px;
}

.filter__indicators {
    display: none !important;
}
.filter__control {
    width: 170px;
    min-height: 30px !important;
    border-radius: 0 !important;
    background: var(--grey) !important;
    border-color: transparent !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, .25) !important;
    position: relative;
}
.filter__control::after {
    position: absolute;
    content: '';
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../../images/arrow-down.svg") no-repeat;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.filter__control:hover,
.filter__control:focus{
    border-color: var(--green) !important;
}

.filter__control--menu-is-open {
    border-color: var(--green) !important;
}

.filter__control--is-focused {
    border-color: transparent !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, .25) !important;
}

.filter__menu {
    margin: 0 !important;
    border-radius: 0 !important;
    font-size: 14px;
}


.filter__menu .filter__option {
    cursor: pointer !important;
}

.filter__menu .filter__option:hover,
.filter__option--is-selected {
    background: var(--green) !important;
    color: #fff !important;
}