.statistic__wrapper h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 30px;
}

.statistic__wrapper h3 {
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
}

.statistic__wrapper .total__stats_block {
    padding-bottom: 18px;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--grey);
    position: relative;
}

.statistic__wrapper .total__stats_block .company__name {
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    margin-right: 20px;
}

.statistic__wrapper .total__stats_block .company__logo {
    width: 140px;
    height: 40px;
}

.statistic__wrapper .total__stats_block .company__info {
    position: relative;
}

.statistic__wrapper .total__stats .data__types {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--grey);
}

.statistic__wrapper .total__stats .data__filter {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--grey);
}

.statistic__wrapper .total__stats .data__filter p {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
}

.statistic__wrapper .total__stats .extra__filter {
    padding-bottom: 20px;
}

.statistic__wrapper .total__stats .extra__filter_params {
    margin-top: 20px;
}

.statistic__wrapper .total__stats .extra__filter_title {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    padding-right: 30px;
    display: inline-block;
}

.statistic__wrapper .total__stats .extra__filter .extra__filter_title::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background: url("../../images/arrow-down.svg") no-repeat;
    height: 12px;
    width: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-out;
}

.statistic__wrapper .total__stats .extra__filter .toggled.extra__filter_title::after {
    transform: rotate(-180deg);
    top: 30%;
}

.statistic__wrapper .total__stats .selected__options {
    color: #575757;
    margin-bottom: 15px;
}

.statistic__wrapper .total__stats .selected__options p {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
}

.statistic__wrapper .total__stats .selected__options .selected__block {
    margin-bottom: 5px;
}

.statistic__wrapper .total__stats .selected__options .selected__block .selected__offer {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    font-weight: bold;
}

.statistic__wrapper .total__stats .selected__options .selected__block .selected__option_item {
    padding: 6px 50px 6px 15px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 15px;
}

.statistic__wrapper .total__stats .selected__options .selected__block .selected__option_item span {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.statistic__wrapper .total__stats .buttons__block {
    padding-bottom: 20px;
}

.statistic__wrapper .total__stats .summary {
    font-size: 12px;
    line-height: 100%;
    padding: 20px 0 20px 40px;
    margin-top: 20px;
    border-bottom: 2px solid var(--grey);
    border-top: 2px solid var(--grey);
}

.statistic__wrapper .total__stats .summary p + p {
    margin-top: 10px;
}

.statistic__wrapper .total__stats .plug__block {
    height: 30px;
}

.statistic__wrapper .offers__stats {
    margin-top: 30px;
}

.statistic__wrapper .offers__stats h3 {
    margin-bottom: 20px;
}

.statistic__wrapper .offers__stats .offer__stats_block {
    margin: -10px -10px;
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item {
    padding: 10px 10px;
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item .offer__stats_wrap {
    background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    padding: 20px 30px;
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item .offer__stats_wrap .offer__stats_title {
    padding-bottom: 10px;
    border-bottom: 2px solid var(--grey);
    margin-bottom: 10px;
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item .offer__stats_wrap .offer__stats_title .offer__stats_name {
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item .offer__stats_wrap .offer__stats_title .offer__stats_logo {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item .offer__stats_wrap .offer__stats_summary {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 2px solid var(--grey);
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item .offer__stats_wrap .offer__stats_summary p {
    font-size: 12px;
    line-height: 100%;
}

.statistic__wrapper .offers__stats .offer__stats_block .offer__stats_item .offer__stats_wrap .offer__stats_summary p + p {
    margin-top: 5px;
}

.statistic__wrapper .params {
    color: #575757;
    background: var(--grey);
    font-size: 14px;
    line-height: 100%;
    width: 140px;
    height: 30px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    border-radius: 31px;
    border: none;
    margin-right: 10px;
    transition: background 0.3s ease-out;
    cursor: pointer;
}

.statistic__wrapper .params:hover {
    color: #fff;
    background: var(--dark-blue);
}

.statistic__wrapper .params.active {
    color: #fff;
    background: var(--dark-blue);
}


@media (max-width: 767px) {
    .statistic__wrapper .total__stats .total__stats_title {
        margin-bottom: 20px;
    }

    .statistic__wrapper .total__stats .data__types button {
        width: 100%;
    }

    .statistic__wrapper .total__stats .data__types button:first-child {
        margin-bottom: 10px;
    }

    .statistic__wrapper .total__stats .data__filter button {
        width: 100%;
    }

    .statistic__wrapper .total__stats .data__filter button:first-child {
        margin-bottom: 10px;
    }
}
