.formControl.error input,
.formControl.error textarea {
    border: 1px solid red;
}

.formControl span {
    color: red;
}

.formSummaryError {
    color: red;
}